import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import Title from "@coninsa-s2/title";
import SimpleCard from "@coninsa-s2/simple-card";
import Button from "@coninsa-s2/button";
import AdCard from "@coninsa-s2/ad-card";

import data from "./data";

export default function OurBenefits() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: 0,
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: 0,
        perPage: 1,
        padding: { left: 0, right: "1.5rem" },
        pagination: true,
      },
    },
  };

  return (
    <div>
      <Section tint="ligther-gray">
        <Container>
          <div>
            <Heading
              as="h2"
              size="4xl"
              tint="teal"
              condensed
              className="!mb-6 !font-medium"
            >
              Nuestros beneficios
            </Heading>
            <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
              {data.map((item) => (
                <SplideSlide>
                  <div className="h-full px-2 pb-4 pt-2">
                    <SimpleCard key={item.id} icon={item.icon}>
                      <Title>{item.title}</Title>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </SimpleCard>
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </Container>
      </Section>

      <Section>
        <div className="-mt-16 flex justify-center px-5 lg:-mt-20 lg:px-0">
          <AdCard tint="teal" className="rounded-lg">
            <div className="s2-ad-card__content">
              <div className="text-center lg:text-start">
                <div className="s2-ad-card__title">
                  Comienza el proceso de publicación{" "}
                  <span className="lg:block">
                    de tu apartamento o casa en arriendo
                  </span>
                </div>
                <p className="s2-ad-card__description">
                  Encuentramos el inquilino ideal para tu inmueble y así{" "}
                  <span className="lg:block">
                    comiences a recibir la renta de tu propiedad.
                  </span>
                </p>
              </div>
              <Button
                href="#"
                shape="square"
                tint="white"
                className="uppercase !text-s2-teal hover:!text-s2-white"
              >
                Quiero publicar ahora
              </Button>
            </div>
          </AdCard>
        </div>
      </Section>
    </div>
  );
}
