import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";
import Button from "@coninsa-s2/button";

import consignmentImage from "../assets/images/banner.png";
import consignmentMobileImage from "../assets/images/mobile-banner.png";

export default function ConsignmentBanner() {
  return (
    <div>
      <Banner
        imageUrl={consignmentImage}
        imageUrlMobile={consignmentMobileImage}
        className="flex items-start lg:items-center lg:pl-16"
      >
        <Container>
          <div className="text-center text-white lg:py-4 lg:text-left">
            <h1>
              <div className="text-2xl font-extrabold lg:mb-3 lg:text-5xl">
                Publica tu propiedad de
              </div>
              <DialogBox tint="teal" className="!px-3 lg:!px-10">
                <span className="text-[28px] font-extrabold lg:text-6xl">
                  vivienda en arriendo
                </span>
              </DialogBox>
            </h1>

            <p className="mb-32 mt-6 text-xl font-medium leading-5 lg:mb-4 lg:text-4xl">
              Encontramos las personas indicadas
              <span className="lg:block">para tu inmueble</span>
            </p>
            <Button
              href="#"
              tint="alternative-lime"
              className="!inline-flex !justify-items-start !text-lg"
            >
              Empezar ahora
            </Button>
          </div>
        </Container>
      </Banner>
    </div>
  );
}
